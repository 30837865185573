<template>
  <b-modal
    :id="`${layout}-layout-preview`"
    title="Layout Preview"
    scrollable
  >
    <div v-if="layout === 'product'">
      <b-row>
        <b-col>
          <b-row>
            <b-col
              class="item product_content_1"
              :class="{ focused: highlight === 'product_content_1'}"
            >
              {{ $t('Content') }} #1
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="item product_image_2"
              :class="{ focused: highlight === 'product_image_2'}"
            >
              {{ $t('Image') }} #2
            </b-col>
            <b-col
              class="item product_image_3"
              :class="{ focused: highlight === 'product_image_3'}"
            >
              {{ $t('Image') }} #3
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="item product_content_2"
              :class="{ focused: highlight === 'product_content_2'}"
            >
              {{ $t('Content') }} #2
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              class="item double-height product_image_1"
              :class="{ focused: highlight === 'product_image_1'}"
            >
              {{ $t('Image') }} #1
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="item double-height product_image_4"
              :class="{ focused: highlight === 'product_image_4'}"
            >
              {{ $t('Image') }} #4
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item product_image_5"
          :class="{ focused: highlight === 'product_image_5'}"
        >
          {{ $t('Image') }} #5
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-row>
            <b-col
              class="item double-height product_image_6"
              :class="{ focused: highlight === 'product_image_6'}"
            >
              {{ $t('Image') }} #6
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="item product_content_4"
              :class="{ focused: highlight === 'product_content_4'}"
            >
              {{ $t('Content') }} #4
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="item product_image_8"
              :class="{ focused: highlight === 'product_image_8'}"
            >
              {{ $t('Image') }} #8
            </b-col>
            <b-col
              class="item product_image_9"
              :class="{ focused: highlight === 'product_image_9'}"
            >
              {{ $t('Image') }} #9
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              class="item product_content_3"
              :class="{ focused: highlight === 'product_content_3'}"
            >
              {{ $t('Content') }} #3
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="item triple-height product_image_7"
              :class="{ focused: highlight === 'product_image_7'}"
            >
              {{ $t('Image') }} #7
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item product_epilogue"
          :class="{ focused: highlight === 'product_epilogue'}"
        >
          {{ $t('epilogue') }}
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item product_general_description"
          :class="{ focused: highlight === 'product_general_description'}"
        >
          {{ $t('generalDescription') }}
        </b-col>
      </b-row>
    </div>

    <div v-else-if="layout === 'company'">
      <b-row>
        <b-col
          class="item company_gallery"
          :class="{ focused: highlight === 'company_gallery'}"
        >
          {{ $t('Gallery') }}
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item company_hero_image"
          :class="{ focused: highlight === 'company_hero_image'}"
        >
          {{ $t('Hero Image') }}
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item"
          :class="{ focused: highlight === 'company_contact_image'}"
        >
          {{ $t('Contact Image') }}
        </b-col>
        <b-col class="item">
          {{ $t('Contact Info') }}
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item company_content_1"
          :class="{ focused: highlight === 'company_content_1'}"
        >
          {{ $t('Content') }} #1
        </b-col>
        <b-col
          class="item company_image_1"
          :class="{ focused: highlight === 'company_image_1'}"
        >
          {{ $t('Image') }} #1
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="item company_image_2"
          :class="{ focused: highlight === 'company_image_2'}"
        >
          {{ $t('Image') }} #2
        </b-col>
        <b-col
          class="item company_content_2"
          :class="{ focused: highlight === 'company_content_2'}"
        >
          {{ $t('Content') }} #2
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  props: {
    layout: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      highlight: null,
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.highlight = this.$root[this.layout]
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.item {
  text-align: center;
  color: $primary;
  height: 150px;
  line-height: 150px;
  border: 1px solid $primary;
  margin: 10px;

  &.focused {
    color: white;
    font-weight: 700;
    background-color: $primary;
  }

  &.double-height {
    height: 235px;
    line-height: 235px;
  }

  &.triple-height {
    height: 405px;
    line-height: 405px;
  }
}
</style>
